/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"

import AutomatedPolicyUpdates from "src/componentsV2/AutomatedPolicyUpdates"

function isMobile(): boolean {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem))
}

const AutomatedPolicyUpdatesPage = ({ ...props }) => {
  const [isMobileChecked, setIsMobileChecked] = useState<boolean>(false)
  useEffect(() => {
    if (window !== undefined && window.analytics) {
      if (isMobileChecked) return

      window.analytics.identify({
        Intercom: { hideDefaultLauncher: isMobile() },
      })

      setIsMobileChecked(true)
    }
  })

  return (
    <React.Fragment>
      <Helmet>
        <meta name="robots" content="all" />
        <title>AirMason | Automated Policy Updates for your employee handbook, curated by Legal HR experts</title>
        <meta name="description" content="AirMason can now not just create your employee handbook, but can keep your policies updated at all times. Leveraging AI, but always overseen and reviewed by HR and legal experts. Saving you the time and hassle of having to constantly keep track and update policies and employee handbooks manually." />
      </Helmet>
      <AutomatedPolicyUpdates {...props} />
    </React.Fragment>
  )
}

export default AutomatedPolicyUpdatesPage
